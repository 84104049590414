import React from "react";
import { Link, Outlet } from "react-router-dom";

// mui components
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import MuiLink from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";

// custom components
import PlantCard from "../components/plant-card";

export default function Home(props) {
  const theme = useTheme();
  // calculate total plant output
  const totalPerformance = () => {
    let performance = 0;
    props.anlagendaten.forEach((plant) => (performance += plant.capacity_dc));
    return performance.toFixed(2).replace(".", ",");
  };

  return (
    <Container
      maxWidth="false"
      sx={{
        bgcolor: "#fafafa",
        pb: 2,
        minHeight: "100vh",
        px: { lg: "64px !important" },
        ...(props.sidebarOpen && {
          pl: { lg: `444px` },
        }),
        transition: theme.transitions.create("padding", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Toolbar />
      <Typography pt={4} variant="h5" component="div">
        Meine Anlagen
      </Typography>
      <Typography mt={1} color="text.secondary" component="div">
        Gesamtleistung: {totalPerformance()} kWp
      </Typography>
      <Typography mt={1} color="text.secondary" component="div">
        Aktueller Spotmarktpreis: {props.electricityPrice}
      </Typography>
      <Stack maxWidth="lg" mt={4} mb={2} spacing={2}>
        {props.anlagendaten.length > 0 ? (
          props.anlagendaten.map((anlage) => {
            return (
              <MuiLink
                component={Link}
                to={`/anlagen/${anlage.id}`}
                key={anlage.id}
                underline="none"
                color="inherit"
              >
                <PlantCard
                  object_ref={anlage.ref}
                  name={anlage.name}
                  capacity_dc={anlage.capacity_dc}
                  inverters={anlage.inverters}
                />
              </MuiLink>
            );
          })
        ) : (
          <>
            <Skeleton
              sx={{ bgcolor: "grey.200" }}
              animation="wave"
              variant="rectangular"
              height={200}
            />
            <Skeleton
              sx={{ bgcolor: "grey.200" }}
              animation="wave"
              variant="rectangular"
              height={200}
            />
            <Skeleton
              sx={{ bgcolor: "grey.200" }}
              animation="wave"
              variant="rectangular"
              height={200}
            />
            <Skeleton
              sx={{ bgcolor: "grey.200" }}
              animation="wave"
              variant="rectangular"
              height={200}
            />
            <Skeleton
              sx={{ bgcolor: "grey.200" }}
              animation="wave"
              variant="rectangular"
              height={200}
            />
          </>
        )}
      </Stack>
      <Outlet />
    </Container>
  );
}
