import * as React from "react";
import { Link } from "react-router-dom";

// mui components
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";

// icons
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

// snippets
import PlantAccordion from "../snippets/drawerAccordion";
import EcolutionIcon from "../snippets/icon-ecolution";

// store PWA app install prompt to variable
let deferredPrompt = null;
window.addEventListener("beforeinstallprompt", (e) => {
  deferredPrompt = e;
});

async function handlePwaInstall() {
  if (deferredPrompt !== null) {
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === "accepted") {
      deferredPrompt = null;
    }
  }
}

export default function Sidebar(props) {
  const breakpoints = useTheme().breakpoints.values;

  function toggleSidebar() {
    if (window.innerWidth <= breakpoints.lg) {
      return props.toggleDrawer();
    }
  }

  return (
    <React.Fragment>
      <Drawer
        anchor={"left"}
        open={props.sidebarOpen}
        onClose={props.toggleDrawer()}
        variant="persistent"
        hideBackdrop={true}
        disableAutoFocus
      >
        <Box
          sx={{
            width: "100vw",
            maxWidth: { xs: 430, lg: 380 },
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          role="presentation"
        >
          <Box>
            <Toolbar />
            <List>
              <MuiLink
                component={Link}
                to={"/"}
                underline="none"
                color="inherit"
                onClick={toggleSidebar()}
              >
                <ListItem>
                  <Box sx={{ display: "flex" }}>
                    <EcolutionIcon sx={{ mr: 1 }} />
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        PV-Support
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </MuiLink>
            </List>
            <Divider />

            <PlantAccordion
              toggleDrawer={props.toggleDrawer}
              anlagendaten={props.anlagendaten}
            />

            <Divider />
            <List>
              <MuiLink
                component={Link}
                to={"/marketPrices"}
                underline="none"
                color="inherit"
                onClick={toggleSidebar()}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Börsenpreise"} />
                  </ListItemButton>
                </ListItem>
              </MuiLink>
              <MuiLink
                component={Link}
                to={"/terms"}
                underline="none"
                color="inherit"
                onClick={toggleSidebar()}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ErrorOutlineOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Impressum und Datenschutz"} />
                  </ListItemButton>
                </ListItem>
              </MuiLink>
              <ListItem
                sx={{ display: deferredPrompt === null && "none" }}
                onClick={handlePwaInstall}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary={"App zum Homescreen"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Box>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={props.handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
