import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

// mui components
import { Box, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Terms(props) {
  const theme = useTheme();
  const timeScale = props.timeScale ? props.timeScale : [];
  const marketPrices = props.marketPrices ? props.marketPrices : [];

  return (
    <Container
      maxWidth="false"
      sx={{
        bgcolor: "#fafafa",
        pb: 2,
        minHeight: "100vh",
        ...(props.sidebarOpen && {
          pl: { lg: `404px` },
        }),
        transition: theme.transitions.create("padding", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Box pt={15} pr={{ sm: 2, md: 6, lg: 10 }} pl={{ sm: 2, md: 6, lg: 10 }}>
        <Typography variant="h4" mb={2}>
          Börsenpreise
        </Typography>
        <Typography mb={2}>
          Es handelt sich bei Börsenpreisen um die Daten der EPEX Spot®
          Strombörse, die jeden Tag um 14:00 Uhr für den nächsten Tag
          aktualisiert werden. Die Preise werden in Cent/kWh für die nächsten 24
          Stunden angegeben.
        </Typography>
      </Box>
      <Box
        pr={{ sm: 2, md: 6, lg: 10 }}
        pb={10}
        pl={{ sm: 2, md: 6, lg: 10 }}
        mb={2}
      >
        <BarChart
          xAxis={[
            {
              id: "time",
              data: timeScale,
              scaleType: "band",
              categoryGapRatio: 0.6,
            },
          ]}
          series={[
            {
              data: marketPrices,
              color: "#ffb400",
              valueFormatter: (value) => value + " Cent / kWh",
            },
          ]}
          height={400}
        />
      </Box>
    </Container>
  );
}
