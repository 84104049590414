import React, { useState, useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PullToRefresh from "pulltorefreshjs";

// base styles
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";

// route templates
import Login from "./routes/login";
import Home from "./routes/home";
import Plant from "./routes/plant";
import Terms from "./routes/terms";
import MarketPrices from "./routes/marketPrices";

// components
import ScrollToTop from "./components/scrollToTop";
import AppBar from "./components/appBar";

// api calls
import { getPlantData, getInverters } from "./utils/apiCalls";
import { getExchangePrice } from "./utils/exchangePrice";

function App(props) {
  const [userLogin, setUserLogin] = useState(false);
  const [termsStatus, setTermsStatus] = useState(false);
  const [plantData, setPlantData] = useState([]);
  const [plantDataDisplay, setPlantDataDisplay] = useState([]);
  const [electricityPrice, setElectricityPrice] = useState([]);

  // for sidebar toggle
  const breakpoints = useTheme().breakpoints.values;

  // handle sidebar toggle
  const initalSidebar = window.innerWidth <= breakpoints.lg ? false : true;
  const [sidebarOpen, setSidebarOpen] = useState(initalSidebar);

  const toggleDrawer = () => (event) => {
    setSidebarOpen(!sidebarOpen);
  };

  // handle search input
  const handleSearch = (searchvalue) => {
    setPlantDataDisplay(
      plantData.filter((plant) =>
        plant.name.toLowerCase().includes(searchvalue.toLowerCase())
      )
    );
  };

  // handle logout remove all saved data from sessionStorage and reload window
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.assign("/");
  };

  const handleTerms = () => {
    setTermsStatus(true);

    if (sessionStorage.getItem("token")) {
      setUserLogin(true);
    }

    // Save terms consent status to localStorage
    localStorage.setItem("termsConsent", "accepted");
  };

  useLayoutEffect(() => {
    localStorage.getItem("termsConsent") === "accepted" && setTermsStatus(true);

    async function processPlants(plants) {
      let result;
      let promises = [];

      for (let i = 0; i < plants.length; i++) {
        promises.push(getInverters(plants[i].ref).then((data) => data));
      }

      result = await Promise.all(promises);

      for (let i = 0; i < plants.length; i++) {
        // modify inverters data
        plants[i].inverters["inverterlist"] = result[i];
        // add array with not producing inverters
        plants[i].inverters["notProducing"] = result[i].filter(
          (inv) => inv.active === false && inv.communicating !== false
        );
        // add array with not communicating inverters
        plants[i].inverters["notCommunicating"] = result[i].filter(
          (inv) => inv.active === false && inv.communicating === false
        );
      }

      return plants;
    }

    async function addInverters(plants) {
      let result = await processPlants(plants);
      setPlantData(result);
      setPlantDataDisplay(result);
      // add plants data as json sting to session storage
      sessionStorage.setItem("plants", JSON.stringify(result));
    }

    // when there is an active session save status to state once
    if (sessionStorage.getItem("token")) {
      setUserLogin(true);
    }

    // when the user is loged in load plant data
    if (userLogin && plantData.length === 0) {
      getPlantData()
        .then((response) => addInverters(response.data))
        .catch(function (error) {
          console.log("error getting plants data:", error);
        });
    }

    // get electricity exchange prices
    if (!electricityPrice[0]) {
      getExchangePrice()
        .then((response) => setElectricityPrice(response))
        .catch(function (error) {
          setElectricityPrice([
            "Die Börse ist aktuell nicht erreichbar",
            "Die Börse ist aktuell nicht erreichbar",
          ]);
        });
    }

    // init pulltorefresh
    PullToRefresh.init({
      mainElement: "body",
      onRefresh() {
        window.location.reload();
      },
      instructionsRefreshing: " ",
      instructionsPullToRefresh: " ",
      instructionsReleaseToRefresh: " ",
      iconArrow: " ",
      iconRefreshing: " ",
    });

    // cleanup pull to refresh
    return () => {
      PullToRefresh.destroyAll();
    };
  }, [userLogin, plantData, electricityPrice]);

  return (
    <React.Fragment>
      <CssBaseline />
      {userLogin && termsStatus ? (
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route
                path="/"
                element={
                  <AppBar
                    anlagendaten={plantDataDisplay}
                    handleLogout={handleLogout}
                    handleSearch={handleSearch}
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    toggleDrawer={toggleDrawer}
                  />
                }
              >
                <Route
                  index
                  element={
                    <Home
                      anlagendaten={plantDataDisplay}
                      sidebarOpen={sidebarOpen}
                      electricityPrice={electricityPrice[0]}
                    />
                  }
                />
                <Route
                  path="anlagen"
                  element={
                    <Home
                      anlagendaten={plantDataDisplay}
                      sidebarOpen={sidebarOpen}
                    />
                  }
                />
                <Route
                  path="anlagen/:anlagenId"
                  element={
                    <Plant anlagendaten={plantData} sidebarOpen={sidebarOpen} />
                  }
                />
                <Route
                  path="terms"
                  element={<Terms sidebarOpen={sidebarOpen} />}
                />
                <Route
                  path="marketPrices"
                  element={
                    <MarketPrices
                      sidebarOpen={sidebarOpen}
                      timeScale={electricityPrice[1]}
                      marketPrices={electricityPrice[2]}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <main
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        padding: "1rem",
                      }}
                    >
                      <div>Die Seite konnte leider nicht gefunden werden!</div>
                    </main>
                  }
                />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      ) : (
        <Login
          termsStatus={termsStatus}
          userLogin={userLogin}
          setUserLogin={setUserLogin}
          handleTerms={handleTerms}
        />
      )}
    </React.Fragment>
  );
}

export default App;
