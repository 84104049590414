import axios from "axios";

// get electricity exchange price from Tado api
function getExchangePrice() {
  // make request to get plant data
  const config = {
    method: "get",
    url: "https://api.awattar.de/v1/marketdata",
  };

  return axios(config)
    .then(function (response) {
      const data = response.data.data;
      // process data to return only the current electricity price
      const currentPrice = data[0];
      const priceWithUnit =
        (currentPrice.marketprice / 10).toFixed(2) + " Cent / kWh";

      const formattedPrice = priceWithUnit.replace(".", ",");

      // process data to return timescale and prices for the next 24 hours
      const timeScale = [];
      const marketPrices = [];

      data.forEach((price) => {
        const date = new Date(price.start_timestamp);
        timeScale.push(date.getHours() + " Uhr");

        marketPrices.push((price.marketprice / 10).toFixed(2));
      });

      return [formattedPrice, timeScale, marketPrices];
    })
    .catch(function (error) {
      console.log("Error electricity exchange prices:", error);
      return error;
    });
}

export { getExchangePrice };
